/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import "./banner.m.css";
import { getPaywallButtons } from "./paywall-button";
import { getTextContrast } from "../../../utils";
import { getLoginLink } from "../../pages/login/sso-utils";

export function PayWall({
  wallTitle = "",
  wallDescription = "",
  signInTxt = "",
  buttonTxt = "",
  bottomTxt = "",
  buttonType = "",
  isPbPreview = false,
  member = {},
  sso,
  paywallSettings = {},
  enableDarkMode,
  localizedMagazineSubscription,
  magazineSlug,
}) {
  const { loginButtonColor, loginDarkButtonColor, subscribeButtonColor, subscribeDarkButtonColor } = paywallSettings;
  const buttonColor = enableDarkMode ? subscribeDarkButtonColor : subscribeButtonColor;
  const buttonTextColor = getTextContrast(buttonColor);
  const signTextColor = enableDarkMode ? loginDarkButtonColor : loginButtonColor;

  function navigateToLogin(e) {
    if (e.target?.tagName === "SPAN") return getLoginLink(member, sso);
  }

  const signInLink = isPbPreview
    ? `<a class="login-text-color" style="color:${signTextColor};">${signInTxt}</a>`
    : `<span class="login-text-color" style="color:${signTextColor};cursor:pointer;">${signInTxt}</span>`;

  bottomTxt = bottomTxt.replace("[Sign in]", signInLink) || "";

  return (
    <div className="element-wrapper" id="metered-paywall-banner">
      <div className="paywall-banner" styleName="paywall-banner">
        <div styleName="banner-title" dangerouslySetInnerHTML={{ __html: wallTitle }} />
        <div styleName="banner-description" dangerouslySetInnerHTML={{ __html: wallDescription }} />
        {getPaywallButtons(
          buttonType,
          buttonTxt,
          buttonColor,
          buttonTextColor,
          member,
          sso,
          localizedMagazineSubscription,
          magazineSlug,
          isPbPreview
        )}
        <div
          styleName="banner-bottom-text"
          onClick={(e) => navigateToLogin(e)}
          dangerouslySetInnerHTML={{ __html: bottomTxt }}
        />
      </div>
    </div>
  );
}

export default PayWall;
