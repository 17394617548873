/* eslint-disable react/display-name */
/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import set from "lodash/set";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { defaultPremiumIconColor, defaultTheme, defaultDarkTheme } from "../../constants";

const WithArrowConfig =
  (Component) =>
  ({ collection = {}, config = {}, index, updateAdAndWidgetComponent }) => {
    const pageType = useSelector((state) => get(state, ["qt", "pageType"], ""), shallowEqual);
    const supportedPages = {
      "home-page": "home",
      "section-page": "section",
      "collection-page": "section",
      "tag-page": "tag",
      "author-page": "author",
      "pagebuilder-author-preview-page": "author",
      "pagebuilder-tag-preview-page": "tag",
      "pagebuilder-section-preview-page": "section",
      "pagebuilder-collection-preview-page": "section",
      "pagebuilder-story-preview-page": "story",
      "pagebuilder-authors-preview-page": "authors",
      "authors-page": "authors",
      "magazine-landing-page": "magazine-landing",
      "issue-landing-page": "issue-landing",
      "magazine-archive-page": "archive",
      "": "",
    };

    const magazinePage = pageType === "magazine-landing-page" || pageType === "issue-landing-page";
    const updatedPath = !magazinePage ? [supportedPages[pageType]] : ["magazine", supportedPages[pageType]];
    const enableDarkMode = useSelector((state) => get(state, ["header", "isDarkModeEnabled"], false));
    const { "pagebuilder-config": pbconfig } = useSelector((state) => get(state, ["qt", "config"], {}));
    let theme = get(pbconfig, [...updatedPath, "pageSetting", "bgColor"], defaultTheme);

    const fallbackImageUrl = get(pbconfig, ["general", "fallbackImage", "url"], "");
    const timeAgoFormat = get(pbconfig, ["general", "timeAgoFormat"], "time unit ago");
    const updateConfig = (config = {}) => {
      const pbRowsConfig = useSelector((state) =>
        get(state, ["qt", "config", "pagebuilder-config", "general", "rows"], {})
      );
      const { enablePremiumStoryIcon = false, iconStyle = "star" } = get(pbRowsConfig, ["premiumStoryIcon"], {});
      let iconColor = get(pbRowsConfig, ["premiumStoryIcon", "iconColor"], defaultPremiumIconColor);
      let borderColor = get(pbRowsConfig, ["sectionTag", "color"], "");
      let collectionNameBorderColor = get(pbRowsConfig, ["titleStyle", "underLineColor"], "");

      const collectionNameTemplate = get(pbRowsConfig, ["collectionNameTemplate"], "");
      const supportedPagesForTitle = ["author-page", "tag-page"];

      const pages = supportedPagesForTitle.includes(pageType);

      const { enableLocalization = false, localizedElements = {} } = useSelector((state) =>
        get(state, ["qt", "config", "pagebuilder-config", "general", "localization"], {})
      );

      const { months, meridiem, publishTime, buttonLabels = {} } = localizedElements;
      const { timeToRead = "", keyEvents } = buttonLabels;
      const localizationConfig = enableLocalization
        ? {
            localizedTimeToRead: timeToRead,
            localizedMonths: months,
            localizedMeridiem: meridiem,
            localizedPublishTime: publishTime,
            localizedKeyEvents: keyEvents,
          }
        : {};

      // Arrow needs the config values like sectionTagTemplate directly under config prop.
      // TODO: May need to flatten the rowConfig sometime.
      const sectionTagTemplate = get(
        pbRowsConfig,
        ["sectionTag", "template"],
        get(pbRowsConfig, ["sectionTagTemplate"], "solid")
      );
      const configObj = { ...config };

      set(configObj, ["theme"], get(config, ["theme"], theme));

      if (enableDarkMode) {
        borderColor = get(pbRowsConfig, ["sectionTag", "darkColor"], "#3cab92");
        // borderColor to change sectionTag color. TODO: rename to sectionTagColor
        collectionNameBorderColor = get(pbRowsConfig, ["titleStyle", "darkUnderLineColor"], "#3cab92");
        iconColor = get(pbRowsConfig, ["premiumStoryIcon", "darkIconColor"], defaultPremiumIconColor);
        theme = get(pbconfig, [...updatedPath, "pageSetting", "darkBgColor"], defaultDarkTheme);
        set(configObj, ["theme"], get(config, ["darkTheme"], theme));
        set(configObj, ["customBulletColor"], get(configObj, ["darkCustomBulletColor"], defaultDarkTheme));
      }

      const premiumStoryIconConfig = {
        enablePremiumStoryIcon,
        iconColor,
        iconStyle,
      };

      const updatedConfig = {
        theme,
        ...configObj,
        sectionTagTemplate,
        collectionNameTemplate,
        borderColor,
        collectionNameBorderColor,
        fallbackImageUrl,
        timeAgoFormat,
        ...premiumStoryIconConfig,
        localizationConfig,
        ...(pages && { showRowTitle: false }),
      };

      if (updateAdAndWidgetComponent) {
        return updateAdAndWidgetComponent(updatedConfig);
      }

      return updatedConfig;
    };

    return (
      <Component collection={collection} config={updateConfig(config)} index={index} enableDarkMode={enableDarkMode} />
    );
  };
export default WithArrowConfig;
