/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
const separator = {
  label: "Separator",
  key: "separator",
  type: "separator",
};

const manageSubscriptionsSettings = [
  {
    label: "Page Title",
    type: "quillEditor",
    keyName: "pageTitle",
    placeholder: "Manage Subscriptions",
  },
  {
    label: "Page Description",
    type: "quillEditor",
    keyName: "pageDescription",
    placeholder:
      "View your active and expired subscriptions, and effortlessly switch plans to suit your needs.",
  },
  {
    label: "Active  Subscription",
    type: "quillEditor",
    keyName: "activeSubscription_label",
    placeholder: "Active Subscription(s)",
  },
  {
    label: "Duration",
    type: "quillEditor",
    keyName: "activeSubscription_duration",
    placeholder: "DURATION",
  },
  {
    label: "Auto Payments",
    type: "quillEditor",
    keyName: "activeSubscription_autoPayments",
    placeholder: "AUTO PAYMENTS",
  },
  {
    label: "Autopay Off",
    type: "quillEditor",
    keyName: "autoPayOffTxt",
    placeholder: "OFF",
  },
  {
    label: "Autopay On",
    type: "quillEditor",
    keyName: "autoPayOnTxt",
    placeholder: "ON",
  },
  {
    label: "Change Plan Button",
    type: "quillEditor",
    keyName: "activeSubscription_changePlanBtn",
    placeholder: "Change Plan",
  },
  separator,
  {
    label: "Expired Subscriptions",
    type: "quillEditor",
    keyName: "expiredSubscription_label",
    placeholder: "EXPIRED SUBSCRIPTIONS",
  },
  {
    label: "Expired On",
    type: "quillEditor",
    keyName: "expiredSubscription_expiredOn",
    placeholder: "Expired on",
  },
  separator,
  {
    label: "You don't appear to have a subscription",
    type: "quillEditor",
    keyName: "noSubscription_text",
    placeholder: "You don't appear to have a subscription.",
  },
];
const availablePlansSettings = [
  {
    label: "Page Title",
    type: "quillEditor",
    keyName: "pageTitle",
    placeholder: "Available Plans",
  },
  {
    label: "Page Description",
    type: "quillEditor",
    keyName: "pageDescription",
    placeholder:
      "Explore the available plans you can switch to. Find new options that perfectly match your needs and preferences.",
  },
  {
    label: "Display Plan Images",
    type: "checkbox",
    keyName: "showPlanImages",
    defaultValue: false,
  },
  {
    label: "Choose Plan",
    type: "quillEditor",
    keyName: "choosePlanBtn",
    placeholder: "Choose Plan",
  },
  {
    label: "Note",
    type: "quillEditor",
    keyName: "note",
    placeholder:
      "^ Based on your current subscription, we have applied a prorated discount. The discount is applicable only for the first billing. From the next billing you’ll be charged the base price of the plan.",
  },
];
const planChangeOverviewSettings = [
  {
    label: "Page Title",
    type: "quillEditor",
    keyName: "pageTitle",
    placeholder: "Plan Change Overview",
  },
  {
    label: "Page Description",
    type: "quillEditor",
    keyName: "pageDescription",
    placeholder: "Compare current and new subscription plans at a glance.",
  },
  {
    label: "Current Plan",
    type: "quillEditor",
    keyName: "currentPlanLabel",
    placeholder: "CURRENT PLAN",
  },
  {
    label: "New Plan",
    type: "quillEditor",
    keyName: "newPlanLabel",
    placeholder: "NEW PLAN",
  },
  separator,
  {
    label: "Amount Payable",
    type: "quillEditor",
    keyName: "amountPayableLabel",
    placeholder: "Amount Payable ^",
  },
  {
    label: "Proceed Button",
    type: "quillEditor",
    keyName: "proceedButton",
    placeholder: "Proceed",
  },
  {
    label: "Note",
    type: "quillEditor",
    keyName: "note",
    placeholder:
      "^ Based on your current subscription, we have applied a prorated discount. The discount is applicable only for the first billing. From the next billing you’ll be charged the base price of the plan.",
  },
];

export const generateSettingsForManageSub = (pageType) => {
  switch (pageType) {
    case "manage-subscriptions":
      return { settings: manageSubscriptionsSettings };
    case "available-plans":
      return { settings: availablePlansSettings };
    case "plan-change-overview":
      return { settings: planChangeOverviewSettings };
  }
};
