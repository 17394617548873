/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { setSubscriptionRedirect } from "../../../utils";
import "./footer-subscribe-button.module.css";

export function FooterSubscribeButton() {
  const pbConfig = useSelector((state) => get(state, ["qt", "config", "pagebuilder-config"]));
  const sketchesHost = useSelector((state) => get(state, ["qt", "config", "sketches-host"], ""));
  const { enableLocalization = false, localizedElements = {} } = get(pbConfig, ["general", "localization"], {});
  const { buttonLabels = {} } = enableLocalization ? localizedElements : {};
  const { subscribe: localizedSubscribe } = buttonLabels;
  
  return (
    <div onClick={() => setSubscriptionRedirect("/subscription", sketchesHost)}>
      <div styleName="subscribe-button" data-test-id="footer-subscribe">
        {localizedSubscribe || "Subscribe"}
      </div>
    </div>
  );
}
