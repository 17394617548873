/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";

function checkIfStoryPublic(story) {
  const access = get(story, ["access"]);
  return access === "public" || access === null;
}

const navigateToAuthDomain = async (bridgeKeeperClientId = "") => {
  if (window?.location) {
    const { getOauthAuthorizeUrl } = await import("@quintype/bridgekeeper-js");
    const redirectUrl = `${window.location.origin}/api/auth/v1/oauth/token`;
    const oauthAuthorizeUrl = await getOauthAuthorizeUrl(
      bridgeKeeperClientId,
      redirectUrl,
      encodeURIComponent(window.location.href)
    );
    window.location.replace(oauthAuthorizeUrl);
  }
};

const checkIfSingleSignOn = (sso = {}) => {
  const { enableSingleSignOn = "", bridgeKeeperClientId = "" } = sso;
  return bridgeKeeperClientId && enableSingleSignOn;
};

const getLoginLink = (member, sso = {}) => {
  const navigateToSignInPage = () => {
    window.location.href = `${window?.location?.origin}/sign-in`;
  };

  if (!member?.["verification-status"] && checkIfSingleSignOn(sso)) {
    return navigateToAuthDomain(sso?.bridgeKeeperClientId);
  } else {
    global?.localStorage?.setItem("loginRedirectUrl", window?.location?.href);
    return navigateToSignInPage();
  }
};

export {  checkIfStoryPublic, navigateToAuthDomain, checkIfSingleSignOn, getLoginLink };
