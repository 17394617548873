/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
const emailLabel = {
  label: "Enter Email",
  type: "text",
  keyName: "emailLabel",
  placeholder: "",
};

const phoneEmailLabel = {
  label: "Enter Email/Phone Number",
  type: "text",
  keyName: "phoneEmailLabel",
  placeholder: "",
};

const pageTitle = {
  label: "Page Title",
  type: "quillEditor",
  keyName: "pageTitle",
  placeholder: "",
};

const pageDescription = {
  label: "Page Description",
  type: "quillEditor",
  keyName: "pageDescription",
  placeholder: "",
};

const signInSetting = [
  {
    label: "Continue button",
    type: "text",
    keyName: "button",
    placeholder: "",
  },
  {
    label: "Or",
    type: "text",
    keyName: "orLabel",
    placeholder: "",
  },
  {
    label: "Continue with Google",
    type: "text",
    keyName: "googleLoginLabel",
    placeholder: "",
  },
  {
    label: "Continue with Facebook",
    type: "text",
    keyName: "facebookLoginLabel",
    placeholder: "",
  },
  {
    label: "Continue with LinkedIn",
    type: "text",
    keyName: "linkedInLoginLabel",
    placeholder: "",
  },
  {
    label: "Separator",
    key: "separator",
    type: "separator",
  },
  {
    label: "Additional Info",
    type: "quillEditor",
    keyName: "additionalInfo",
    placeholder: "",
    hint: "Use this field if you want to apprise your readers on any questions they might have on the sign-in/ sign-up process.",
  },
];

const goBackButton = {
  label: "Go Back button",
  type: "text",
  keyName: "goBackButton",
  placeholder: "",
};

const enterOtpLabel = {
  label: "Enter OTP",
  type: "text",
  keyName: "enterOtpLabel",
  placeholder: "",
};

const ResendOtpIn = {
  label: "Resend OTP in",
  type: "text",
  keyName: "resendOtpInLabel",
  placeholder: "",
};

const ResendOtp = {
  label: "Resend OTP",
  type: "text",
  keyName: "resendOtpLabel",
  placeholder: "",
};

const verifySignInButton = {
  label: "Verify & Sign in button",
  type: "text",
  keyName: "button",
  placeholder: "",
};

const enterOtpTitle = {
  label: "Enter the OTP sent to",
  type: "text",
  keyName: "enterOtpTitle",
  placeholder: "",
};

const agreementText = {
  label: "Agreement message",
  type: "quillEditor",
  keyName: "agreementText",
  placeholder: "",
};

const mobileOtpSettings = [
  enterOtpTitle,
  enterOtpLabel,
  ResendOtpIn,
  ResendOtp,
  verifySignInButton,
  agreementText,
  {
    label: "Available Shortcodes On This Page",
    type: "shortCodes",
    keyName: "",
    placeholder: "",
    shortCodeOptions: [
      {
        name: "Phone Number",
        value: "[phone-number]",
      },
      {
        name: "Resend OTP Timer",
        value: "[resend-otp-timer]",
      },
      {
        name: "Terms & Conditions",
        value: "[terms-and-conditions]",
      },
      {
        name: "Privacy Policy",
        value: "[privacy-policy]",
      },
    ],
  },
];

const enterPasswordSettings = [
  {
    label: "Enter the Password for",
    type: "text",
    keyName: "enterPasswordTitle",
    placeholder: "",
  },
  {
    label: "Enter Password",
    type: "text",
    keyName: "enterPasswordLabel",
    placeholder: "",
  },
  {
    label: "Forgot Password link",
    type: "text",
    keyName: "forgotPasswordLink",
    placeholder: "",
  },
  verifySignInButton,
  {
    label: "Available Shortcodes On This Page",
    type: "shortCodes",
    keyName: "",
    placeholder: "",
    shortCodeOptions: [
      {
        name: "Email",
        value: "[email]",
      },
    ],
  },
];

const signUpSettings = [
  pageTitle,
  {
    label: "Already have an account?",
    type: "text",
    keyName: "pageSubTitle",
    placeholder: "",
  },
  {
    label: "Sign in",
    type: "text",
    keyName: "loginLabel",
    placeholder: "",
  },
  emailLabel,
  {
    label: "Create Password",
    type: "text",
    keyName: "passwordLabel",
    placeholder: "",
  },
  {
    label: "Enter Name",
    type: "text",
    keyName: "nameLabel",
    placeholder: "",
  },
  {
    label: "Get OTP button",
    type: "text",
    keyName: "button",
    placeholder: "",
  },
];

const forgotPasswordSettings = [
  pageTitle,
  emailLabel,
  {
    label: "Get OTP button",
    type: "text",
    keyName: "getOtpButton",
    placeholder: "",
  },
  goBackButton,
];

const resetPasswordSettings = [
  pageTitle,
  {
    label: "Enter the OTP sent to your email address",
    type: "text",
    keyName: "pageSubTitle",
    placeholder: "",
  },
  enterOtpLabel,
  ResendOtpIn,
  ResendOtp,
  {
    label: "Create New Password",
    type: "text",
    keyName: "newPasswordLabel",
    placeholder: "",
  },
  verifySignInButton,
  goBackButton,
  {
    label: "Available Shortcodes On This Page",
    type: "shortCodes",
    keyName: "",
    placeholder: "",
    shortCodeOptions: [
      {
        name: "Resend OTP Timer",
        value: "[resend-otp-timer]",
      },
    ],
  },
];

const emailOtpSettings = [
  enterOtpTitle,
  enterOtpLabel,
  ResendOtpIn,
  ResendOtp,
  verifySignInButton,
  agreementText,
  {
    label: "Available Shortcodes On This Page",
    type: "shortCodes",
    keyName: "",
    placeholder: "",
    shortCodeOptions: [
      {
        name: "Email",
        value: "[email]",
      },
      {
        name: "Resend OTP Timer",
        value: "[resend-otp-timer]",
      },
      {
        name: "Terms & Conditions",
        value: "[terms-and-conditions]",
      },
      {
        name: "Privacy Policy",
        value: "[privacy-policy]",
      },
    ],
  },
];

export const generateLoginSetting = (layout = "") => {
  const getSettings = () => {
    switch (layout) {
      case "signIn":
        return [pageTitle, pageDescription, emailLabel, phoneEmailLabel, ...signInSetting];
      case "signUp":
        return signUpSettings;
      case "forgotPassword":
        return forgotPasswordSettings;
      case "resetPassword":
        return resetPasswordSettings;
      case "emailOtp":
        return emailOtpSettings;
      case "mobileOtp":
        return mobileOtpSettings;
      case "enterPassword":
        return enterPasswordSettings;
    }
  };

  return {
    settings: getSettings(),
  };
};

export const generateProfilePageSetting = () => {
  return {
    settings: [
      {
        label: "Page Title",
        type: "quillEditor",
        keyName: "pageTitle",
        placeholder: "My Profile",
      },
      {
        label: "Name Label",
        type: "text",
        keyName: "nameInputLabel",
        placeholder: "Name",
      },
      {
        label: "Email or Phone Number Label",
        type: "text",
        keyName: "emailInputLabel",
        placeholder: "Email or Phone Number",
      },
      {
        label: "Save Button",
        type: "text",
        keyName: "saveButtonLabel",
        placeholder: "Save",
      },
      {
        label: "Discard Changes Button",
        type: "text",
        keyName: "cancelButtonLabel",
        placeholder: "Discard Changes",
      },
    ],
  };
};

export const generatePurchasePageSetting = () => {
  return {
    settings: [
      {
        label: "Purchased Stories Title",
        type: "text",
        keyName: "pageTitle",
        placeholder: "Purchased Stories",
        default: "Purchased Stories",
      },
      {
        label: "Purchased Stories Description",
        type: "textArea",
        keyName: "pageDescription",
        placeholder: "View all the articles you've purchased on this page. Explore your collections and enjoy reading!",
        default: "View all the articles you've purchased on this page. Explore your collections and enjoy reading!",
      },
    ],
  };
};
