/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { renderBreakingNews, renderComponent, renderIsomorphicComponent } from "@quintype/framework/client/start";
import get from "lodash/get";
import WithLazyTrigger from "../isomorphic/components/with-lazy-trigger";
import DFPHelper from "../isomorphic/components/atoms/dfp-helper";
import { MobileDownloadLinks } from "../isomorphic/components/atoms/mobile-apps";
import { LoadingIndicatorComponent } from "../isomorphic/components/basic/loading-indicator.js";
import { BreakingNewsView } from "../isomorphic/components/breaking-news/breaking-news-view";
import { HeaderLogin } from "../isomorphic/components/headers/login";
import { DefaultMenu } from "../isomorphic/components/headers/menu/default-menu";
import { HeaderSearch } from "../isomorphic/components/headers/search";
import { HeaderSidebar } from "../isomorphic/components/headers/sidebar";
import { HeaderSocialFollow } from "../isomorphic/components/headers/social-follow-wrapper";
import { HeaderSticky } from "../isomorphic/components/headers/sticky";
import WithSubsequentLoad from "../isomorphic/components/hoc-components/with-subsequent-load";
import WithArrowConfig from "../isomorphic/components/hoc-components/with-arrow-config";
import { InjectWidget } from "../isomorphic/components/inject-widget";
import { Metype } from "../isomorphic/components/molecules/metype";
import { PBThemeSettings } from "../isomorphic/components/pb-theme";
import { FooterLink } from "../isomorphic/components/rows/footer/link";
import { Menu } from "../isomorphic/components/rows/footer/menu";
import { SocialFollowWrapper } from "../isomorphic/components/rows/footer/social-follow-wrapper";
import { WidgetComponent } from "../isomorphic/components/Widget";
import { pickComponent } from "../isomorphic/pick-component";
import { customCodeWithShortcode } from "../utils/decodeCustomCode";
import { hydrateStory } from "./hydrate-story";
import { AdSlotRow } from "../isomorphic/components/collection-templates/ad-slot-row";
import { Theme } from "../isomorphic/components/theme.js";

import { SecondaryMenu } from "../isomorphic/components/headers/menu/secondary-menu";
import { NewHamburgerMenu } from "../isomorphic/components/headers/new-hamburger-menu";

import { NewSearch } from "../isomorphic/components/headers/header-template-five/menu-with-search-layer";
import { MegaMenu } from "../isomorphic/components/headers/mega-menu";
import { PrimaryMenu } from "../isomorphic/components/headers/primary-menu";
import { AppDownloadLinks } from "../isomorphic/components/atoms/app-download-links";
import { CurrentDateTime } from "../isomorphic/components/atoms/current-date-time";
import { footerTwo, footerThree, headerFive, headerSix } from "../isomorphic/constants";
import { StickyComponent } from "../isomorphic/components/headers/new-header-sticky";
import { maySetGlobals, getCookie } from "../isomorphic/utils";
import { DarkModeToggle } from "../isomorphic/components/headers/dark-mode-toggle";
import { Monogram } from "../isomorphic/components/monogram";
import { HeaderLogo } from "../isomorphic/components/headers/header-logo";
// import { fixDates } from "../utils/story-utils";
import TimeStamp from "../isomorphic/components/timestamp";
import { GoogleOneTap } from "../isomorphic/components/google-one-tap.js";
import { authHeaderEnabled } from "../isomorphic/components/headers/utils";
import { HeaderSubscribeButton } from "../isomorphic/components/headers/header-helper-components.js";
import { FooterSubscribeButton } from "../isomorphic/components/rows/footer/footer-subscribe-button.js";
import { AuthorIntroductionCard, ThreeColGrid, OneColStoryList } from "@quintype/arrow";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell, isDesktop: true };
  const pageType = get(store.getState(), ["qt", "pageType"], "");
  const storeConfig = get(store.getState(), ["qt", "config"], {});
  const pbConfig = storeConfig["pagebuilder-config"];
  const footerTheme = get(pbConfig, ["footer", "footerStyle"], "footer_1");
  const headerTheme = get(pbConfig, ["header", "headerStyle"], "header_1");
  const enableFooterAppDownloadLinks = get(pbConfig, ["footer", "enableAppDownloadLinksButton"], false);
  const enableHeaderAppDownloadLinks = get(pbConfig, ["header", "enableAppDownloadLinksButton"], false);
  const { enableSubscriptionButton = false, dateTime = {}, showDarkModeToggle = false } = get(pbConfig, ["header"], {});
  const { enableDate = false, enableTime = false } = dateTime;
  const enableSSO = get(pbConfig, ["header", "enableSSO"], false);
  const isBreakingNewsEnabled = get(pbConfig, ["general", "breakingNews", "isEnabled"], false);
  const isEnableLiveFeed = get(pbConfig, ["general", "commentPlugin", "meType", "enableLiveFeed"]);
  const isAuthHeaderEnabled = authHeaderEnabled(pageType, pbConfig);
  
  if (pageType === "user-login" || isAuthHeaderEnabled) {
    renderComponent(HeaderSticky, "auth-header-sticky", store, {
      hydrate: true,
      elementId: "auth-header",
      stickyElementId: "auth-header-sticky",
    });
    renderComponent(HeaderLogo, "auth-header-logo", store, { hydrate: true });
  }

  if (pageType === "user-login") {
    return null;
  }

  if (pageType === "author-page") {
    const authorRows = get(pbConfig, ["author", "rows"], []);
    authorRows.forEach(row => {
      switch (row.layout) {
        case "ArrowOneColStoryList":
          renderComponent(OneColStoryList, "one-col-list", store, hydrate);
          break;
        default: // Handles "ArrowThreeColGrid" as the default case
          renderComponent(ThreeColGrid, "three-col-grid", store, hydrate);
          break;
      }
    });
    renderComponent(AuthorIntroductionCard, "author-intro-card", store, hydrate);
  }

  renderComponent(InjectWidget, "widget", store);
  renderComponent(PBThemeSettings, "pbTheme", store);
  renderComponent(FooterLink, "footer-link", store);

  renderComponent(Theme, "theme-css", store);
  renderComponent(Monogram, "footer-logo", store, hydrate);

  renderComponent(HeaderLogo, "header-logo", store, hydrate);
  renderComponent(GoogleOneTap, "google-one-tap", store);

  if (pbConfig && footerTheme === footerTwo) {
    renderComponent(Monogram, "mobile-footer-logo", store, hydrate);
  }

  if (enableSSO) {
    renderComponent(HeaderLogin, "header-login", store, { hydrate: true });
  }

  if (pbConfig && showDarkModeToggle) {
    renderComponent(DarkModeToggle, "dark-mode-toggle", store, hydrate);
  }
  // Header five ----
  const isMobileMenu = { hydrate: !global.qtLoadedFromShell, isMobileMenu: true };

  if (pbConfig && headerTheme === headerFive) {
    renderComponent(NewHamburgerMenu, "new-hamburger-menu", store, hydrate);
    renderComponent(NewSearch, "new-search", store, hydrate);
    renderComponent(MegaMenu, "mega-menu", store, hydrate);
    renderComponent(PrimaryMenu, "new-primary-menu", store, hydrate);
    renderComponent(PrimaryMenu, "mobile-primary-menu", store, isMobileMenu);
    renderComponent(SecondaryMenu, "secondary-menu", store, hydrate);
    renderComponent(DarkModeToggle, "mobile-dark-mode-toggle", store, hydrate);
    renderComponent(HeaderSocialFollow, "sidebar-header-social-follow", store, hydrate);
    renderComponent(HeaderLogo, "header-logo-mobile", store, hydrate);
    renderComponent(StickyComponent, "desktop-sticky-header", store, {
      hydrate: !global.qtLoadedFromShell,
      targetClassName: "desktop-header-layer-two",
      targetInterSection: "desktop-header-layer-two",
      offsetValue: 40,
    });
    renderComponent(StickyComponent, "mobile-sticky-header", store, {
      hydrate: !global.qtLoadedFromShell,
      targetClassName: "mobile-header-layer-two",
      targetInterSection: "mobile-header-layer-one",
      offsetValue: 40,
    });
    enableHeaderAppDownloadLinks && renderComponent(AppDownloadLinks, "app-download-link", store, hydrate);
    if (enableDate || enableTime) {
      renderComponent(CurrentDateTime, "current-date-time", store, hydrate);
    }
    if (enableSSO) {
      renderComponent(HeaderLogin, "header-login-mobile", store, hydrate);
    }
  }

  // ending------
  // ending------

  // header 6 ------------

  if (pbConfig && headerTheme === headerSix) {
    renderComponent(NewHamburgerMenu, "new-hamburger-menu", store, hydrate);
    renderComponent(NewSearch, "new-search", store, hydrate);
    renderComponent(MegaMenu, "mega-menu", store, hydrate);
    renderComponent(PrimaryMenu, "new-primary-menu", store, hydrate);
    renderComponent(PrimaryMenu, "mobile-primary-menu", store, isMobileMenu);
    renderComponent(SecondaryMenu, "secondary-menu", store, hydrate);
    renderComponent(DarkModeToggle, "mobile-dark-mode-toggle", store, hydrate);
    renderComponent(HeaderSocialFollow, "sidebar-header-social-follow", store, hydrate);
    renderComponent(HeaderLogo, "header-logo-mobile", store, hydrate);
    renderComponent(StickyComponent, "sticky-header", store, {
      hydrate: !global.qtLoadedFromShell,
      targetClassName: "header-wrapper",
      targetInterSection: "header-wrapper",
    });

    enableHeaderAppDownloadLinks && renderComponent(AppDownloadLinks, "app-download-link", store, hydrate);
    if (enableDate || enableTime) {
      renderComponent(CurrentDateTime, "current-date-time", store, hydrate);
    }
    if (enableSSO && enableSubscriptionButton === false) {
      renderComponent(HeaderLogin, "header-login-mobile", store, hydrate);
    }
  }

  renderComponent(HeaderSocialFollow, "header-social-follow", store, hydrate);

  if (headerTheme !== headerFive && headerTheme !== headerSix) {
    renderComponent(HeaderSidebar, "header-sidebar", store, { hydrate: true });
    renderComponent(HeaderSearch, "header-search", store, { hydrate: true });
    renderComponent(DefaultMenu, "header-default-menu", store, hydrate);
    renderComponent(HeaderSticky, "header-sticky", store, { hydrate: true });
  }

  if (footerTheme !== "footer_1") {
    renderComponent(SocialFollowWrapper, "social-follow-container", store, { hydrate: true });
    renderComponent(Menu, "footer-menu", store, hydrate);

    if (pbConfig && enableFooterAppDownloadLinks) {
      renderComponent(MobileDownloadLinks, "footer-mobile-download-links", store, { hydrate: true });
    }
  }

  if (
    (headerTheme !== headerFive && headerTheme !== headerSix && !headerTheme.includes("single-layer")) ||
    headerTheme.includes("single-layer-header_5")
  ) {
    renderComponent(HeaderSearch, "header-search-mobile", store, { hydrate: true });
  }

  if (headerTheme !== headerFive && headerTheme !== headerSix && !headerTheme.includes("single-layer")) {
    renderComponent(HeaderSidebar, "header-sidebar-mobile", store, { hydrate: true });
    renderComponent(SecondaryMenu, "header-secondary-menu", store, { hydrate: true });
    if (pbConfig && enableHeaderAppDownloadLinks) {
      renderComponent(MobileDownloadLinks, "header-mobile-download-links", store, { hydrate: true });
    }
  }

  if (isBreakingNewsEnabled) renderBreakingNews("breaking-news-container", store, BreakingNewsView);

  if (isEnableLiveFeed) global.setTimeout(() => renderComponent(Metype, "meType", store), 3000);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  const storeQTData = get(store.getState(), ["qt"], {});
  const storeConfig = get(storeQTData, ["config"], {});
  const pageType = get(storeQTData, ["pageType"], "");
  const pbConfig = storeConfig["pagebuilder-config"];
  const lightRows = get(pbConfig, ["home", "lightRows"], false);
  const footerTheme = get(pbConfig, ["footer", "footerStyle"], "footer_1");
  const headerTheme = get(pbConfig, ["header", "headerStyle"], "header_1");

  const enableFooterAppDownloadLinks = get(pbConfig, ["footer", "enableAppDownloadLinksButton"], false);
  const enableHeaderAppDownloadLinks = get(pbConfig, ["header", "enableAppDownloadLinksButton"], false);
  const enableSSO = get(pbConfig, ["header", "enableSSO"], false);
  const isBreakingNewsEnabled = get(pbConfig, ["general", "breakingNews", "isEnabled"], false);
  const { story = {}, author = {}, section = {} } = get(storeQTData, ["data"], {});
  const checkIfStoryPublic = (story) => {
    const access = get(story, ["access"]);
    return access === "public" || access === null;
  };

  const enableFooterSubscribeButton = get(pbConfig, ["footer", "enableSubscriptionButton"], false);

  const disableServiceWorkerCache = get(pbConfig, ["general", "serviceWorker", "disableCache"], false);
  const { enableSubscriptionButton = false } = get(pbConfig, ["header"], {});

  /*
    Set global variables on client -- to be kept minimalistic;
    is modified later in the file
  */
  global.qtData = {};

  if (pageType === "user-login") {
    return renderIsomorphicComponent("container", store, pickComponent, { hydrate: !global.qtLoadedFromShell });
  }
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);

  if (get(pbConfig, ["general", "ads", "dfpNetworkId"], "")) {
    renderComponent(DFPHelper, "dfp-helper", store, { hydrate: true });
  }

  if (isBreakingNewsEnabled) renderBreakingNews("breaking-news-container", store, BreakingNewsView);

  if (headerTheme !== headerFive && headerTheme !== headerSix) {
    renderComponent(HeaderSidebar, "header-sidebar", store, { hydrate: true });
    renderComponent(HeaderSocialFollow, "header-social-follow", store, { hydrate: true });
    renderComponent(HeaderSearch, "header-search", store, { hydrate: true });
    renderComponent(FooterLink, "footer-link", store);
    renderComponent(HeaderSticky, "header-sticky", store, { hydrate: true });
  }

  if (enableSubscriptionButton) {
    renderComponent(HeaderSubscribeButton, "header-subscribe-button", store, { hydrate: true });
    if (headerTheme === headerFive || headerTheme === headerSix) {
      renderComponent(HeaderSubscribeButton, "mobile-header-subscribe-button", store, { hydrate: true });
    }
  }

  if (enableSSO) {
    renderComponent(HeaderLogin, "header-login", store, { hydrate: true });
  }

  if (enableFooterSubscribeButton) {
    if (footerTheme === footerTwo)
      renderComponent(FooterSubscribeButton, "footer2-subscribe-button", store, { hydrate: true });

    if (footerTheme === footerThree) {
      renderComponent(FooterSubscribeButton, "footer3-subscribe-button", store, { hydrate: true });
    }
  }

  if (footerTheme !== "footer_1") {
    renderComponent(SocialFollowWrapper, "social-follow-container", store, { hydrate: true });

    if (pbConfig && enableFooterAppDownloadLinks) {
      renderComponent(MobileDownloadLinks, "footer-mobile-download-links", store, { hydrate: true });
    }
  }
  if (
    (headerTheme !== headerFive && headerTheme !== headerSix && !headerTheme.includes("single-layer")) ||
    headerTheme.includes("single-layer-header_5")
  ) {
    renderComponent(HeaderSearch, "header-search-mobile", store, { hydrate: true });
  }

  if (headerTheme !== headerFive && headerTheme !== headerSix && !headerTheme.includes("single-layer")) {
    renderComponent(HeaderSidebar, "header-sidebar-mobile", store, { hydrate: true });
    renderComponent(SecondaryMenu, "header-secondary-menu", store, { hydrate: true });
    if (pbConfig && enableHeaderAppDownloadLinks) {
      renderComponent(MobileDownloadLinks, "header-mobile-download-links", store, { hydrate: true });
    }
  }

  const pbConfigRows = get(pbConfig, ["header", "rows"], []);

  pbConfigRows.forEach((row) => {
    if (row.type === "ads") {
      renderComponent(AdSlotRow, `${row.type}-${row.rowId}`, store, {
        metadata: row,
        hydrate: true,
      });
    } else if (row.type === "widget") {
      const id = `${row.type}-${get(row, ["config", "targetingId"]) || row.rowId}`;
      const updatedWidgetCode = customCodeWithShortcode(get(row, ["config", "customCode"], ""), `custom-${id}`);
      renderComponent(WidgetComponent, id, store, {
        widgetValue: `<div>${updatedWidgetCode}</div>`,
      });
    }
  });

  const isStoryInfiniteScrollEnabled =
    get(pbConfig, ["infiniteScroll", "infiniteScrollType"], "disabled") !== "disabled";

  const storyType = get(story, ["story-template"], "");
  const canSelectivelyHydrateStory = ["text"].includes(storyType);

  const getEnableDarkModeCookie = getCookie("enableDarkMode") || false;
  const checkDarkModeEnabled = getEnableDarkModeCookie === "true";

  if (pageType === "story-page" && !isStoryInfiniteScrollEnabled) {
    const storyPath = story.url ? new URL(story.url).pathname : "/" + story.slug;
    const getPageData = (storyKey = "", storyValue = "") => get(story, [storyKey], []).map((item) => item[storyValue]);
    const pageData = {
      "page-type": pageType,
      "story-slug": get(story, ["slug"], null),
      "story-id": get(story, ["id"], null),
      "story-type": get(story, ["story-template"], null),
      "primary-section": get(getPageData("sections", "slug"), [0]) || null,
      sections: getPageData("sections", "slug").join(",") || null,
      authors: getPageData("authors", "name").join(",") || null,
      "sections-name": getPageData("sections", "name").join(",") || null,
      "sections-display-name": getPageData("sections", "display-name").join(",") || null,
      "authors-id": getPageData("authors", "id").join(",") || null,
    };
    maySetGlobals(pageData);
    if (storyPath !== window.location.pathname) {
      const updatedStoryPath = window.location.search ? `${storyPath}${window.location.search}` : storyPath;
      const title = get(story, ["seo", "meta-title"], story.headline);
      global.app.maybeSetUrl(updatedStoryPath, title);
    }
  }
  if (checkDarkModeEnabled) {
    renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
  }
  document.addEventListener("enable-dark-mode", (e) => {
    renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
  });

  // Hydrate story if SW cache is disabled
  if (pageType === "story-page" && disableServiceWorkerCache) {
    return renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
  }

  // Handle Story Page Selective Hydration
  if (pageType === "story-page" && canSelectivelyHydrateStory && !isStoryInfiniteScrollEnabled) {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const isStoryPublic = checkIfStoryPublic(story);
    const target = global && global.document && global.document.getElementById("paywall-banner");
    if (target && !isStoryPublic) {
      const observer = new IntersectionObserver(renderStory, options);
      observer.observe(target);

      function renderStory(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            return renderIsomorphicComponent("container", store, pickComponent, { hydrate: !global.qtLoadedFromShell });
          }
        });
      }
    }
  }

  const hydratePageRows = (pageType) => {
    const page = pageType.split("-")?.[0] || "home";
    const pbConfigRows = get(pbConfig, [page, "rows"], []);
    return pbConfigRows.forEach((row) => {
      const slotConfigType = get(row, ["config", "slotConfig", 0, "type"], "");
      if (slotConfigType === "ad") {
        const { rowId, config } = row;
        const adContainerId = `PB-${rowId}`;
        if (global?.document?.getElementById(adContainerId)) {
          const { targetingId, customCode, adType } = config;
          renderComponent(AdSlotRow, adContainerId, store, {
            metadata: {
              layout: adType,
              config: { targetingId: targetingId, customCode: customCode },
              rowId,
            },
            hydrate: true,
          });
        }
      } else if (slotConfigType === "widget") {
        const widgetContainerId = `widget-slot-${row.rowId}`;
        if (global && global.document) {
          if (global.document.getElementById(widgetContainerId)) {
            const updatedWidgetCode = customCodeWithShortcode(
              get(row, ["config", "widget"], ""),
              `custom-${widgetContainerId}`
            );
            renderComponent(WidgetComponent, widgetContainerId, store, {
              widgetValue: updatedWidgetCode,
              hydrate: true,
            });
          } else {
            const timeoutCounter = setTimeout(() => {
              // Do not hydrate if previously hydrated before
              if (
                global?.document?.getElementById(widgetContainerId) &&
                !global?.document?.getElementById(widgetContainerId)?.hasChildNodes()
              ) {
                const updatedWidgetCode = customCodeWithShortcode(
                  get(row, ["config", "widget"], ""),
                  `custom-${widgetContainerId}`
                );
                renderComponent(WidgetComponent, widgetContainerId, store, {
                  widgetValue: updatedWidgetCode,
                  hydrate: true,
                });
              }
              clearTimeout(timeoutCounter);
            }, 2500);
          }
        }
      }
    });
  };

  if (
    pageType === "story-page" &&
    canSelectivelyHydrateStory &&
    !isStoryInfiniteScrollEnabled &&
    !checkDarkModeEnabled
  ) {
    // Selectively hydrate only when qtLoadedFromShellfrom shell is false
    if (!global.qtLoadedFromShell) {
      renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
      return hydrateStory(store, storeQTData, pbConfig);
    }
  }

  const isSectionPage = pageType === "section-page";
  const isAuthorPage = pageType === "author-page";
  const pageData = {
    "page-type": pageType || null,
    "story-slug": null,
    "story-id": null,
    "story-type": null,
    "primary-section": (isSectionPage && get(section, ["slug"])) || null,
    sections: (isSectionPage && get(section, ["slug"])) || null,
    authors: (isAuthorPage && get(author, ["name"])) || null,
    "sections-name": (isSectionPage && get(section, ["name"])) || null,
    "sections-display-name": (isSectionPage && get(section, ["display-name"])) || null,
    "authors-id": (isAuthorPage && get(author, ["id"])) || null,
  };
  if (pageType === "home-page" && lightRows) {
    maySetGlobals({ ...pageData, "light-rows": true });
    renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
  } else {
    pageType !== "story-page" && maySetGlobals(pageData);
    renderIsomorphicComponent("container", store, pickComponent, { hydrate: !global.qtLoadedFromShell });
    /*
        Loop through pb config to selectively hydrate widget/ad slots in rows where present
      */
    return hydratePageRows(pageType);
  }

  if (pageType === "home-page" && lightRows) {
    const collectionItems = get(storeQTData, ["data", "collection", "items"], []);

    const pbConfigRows = get(pbConfig, ["home", "rows"], []);

    // fixDates(storeConfig, pbConfig);
    /*
        Loop through pb config to check if the collection
        component has load more as subsequent to hydrate it
      */
    pbConfigRows
      .filter((row) => row.type === "collections")
      .forEach(({ config = {}, layout }, index) => {
        const collection = collectionItems[index];
        if (collection) {
          const { enableLocalization = false, localizedElements = {} } = get(pbConfig, ["general", "localization"], {});
          const { enableTimeTranslation } = get(pbConfig, ["general"], {});
          const localizationConfig = enableLocalization
            ? {
                localizedMonths: get(localizedElements, ["months"]),
                localizedMeridiem: get(localizedElements, ["meridiem"]),
                localizedPublishTime: get(localizedElements, ["publishTime"]),
              }
            : {};

          const renderTimestamp = (item, id) => {
            if (global && global.document.getElementById(id)) {
              renderComponent(TimeStamp, id, store, {
                story: { ...item.story },
                config: { enableTimeTranslation, ...localizationConfig },
                hydrate: true,
              });
            }
          };
          collection.items.forEach((item) => {
            if (item.type === "story") {
              const publishTimeId = `publish-time-${collection.id}-${item.id}`;
              renderTimestamp(item, publishTimeId);
            } else if (item.type === "collection") {
              if (item?.items?.length) {
                item.items.forEach((childCollectionItem) => {
                  const publishTimeId = `publish-time-${collection.id}-${item.id}-${childCollectionItem.id}`;
                  renderTimestamp(childCollectionItem, publishTimeId);
                });
              }
            }
          });

          const elementId = `ELM-${collection.id}`;
          const timeoutCounter = setTimeout(() => {
            if (global && global.document && global.document.getElementById(elementId)) {
              renderComponent(WithLazyTrigger, elementId, store, {
                onIntersection: async () => {
                  const ExtendedLoadMore = (await import("../isomorphic/components/elm-default-export")).default;
                  renderComponent(ExtendedLoadMore, elementId, store, {
                    config,
                    componentName: layout,
                    WithArrowConfig,
                    withSubsequentLoad: WithSubsequentLoad,
                  });
                },
              });
            }
            clearTimeout(timeoutCounter);
          }, 2500);
        }
      });

    // Hydration for Home Ad Row start

    /*
        Loop through pb config to selectively hydrate widget rows
      */

    pbConfigRows.forEach((row) => {
      const containerId =
        row.type === "widget" ? `widget-${get(row, ["config", "targetingId"]) || row.rowId}` : `PB-${row.rowId}`;
      if (global && global.document.getElementById(containerId)) {
        if (row.type === "widget") {
          const updatedWidgetCode = customCodeWithShortcode(
            get(row, ["config", "customCode"], ""),
            `custom-${containerId}`
          );
          renderComponent(WidgetComponent, containerId, store, {
            widgetValue: `<div>${updatedWidgetCode}</div>`,
            hydrate: true,
          });
        } else if (row.type === "ads") {
          renderComponent(AdSlotRow, containerId, store, {
            metadata: row,
            hydrate: true,
          });
        }
      }
    });

    /*
        Loop through pb config to selectively hydrate widget/ad slots in rows where present
      */
    hydratePageRows(pageType);
  }
}
